//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapState, mapGetters } from "vuex";
import { queryGeneticAnalystServicesByHashId, updateGeneticAnalystServiceFee, updateGeneticAnalystService } from "@debionetwork/polkadot-provider";
import { getIpfsMetaData } from "@/common/lib/pinata-proxy";
import { pencilIcon, trashIcon } from "@debionetwork/ui-icons";
import AddServiceForm from "@/common/components/Service/AddService";
import DeleteDialog from "@/common/components/Dialog/DeleteServiceDialog";
import SuccessDialog from "@/common/components/Dialog/SuccessDialogGeneral";
import { fromEther, toEther, formatUSDTE } from "@/common/lib/balance-format";
import { uploadFile, getFileUrl } from "@/common/lib/pinata-proxy";
const stepData = [{
  label: "Set Up Account",
  active: false
}, {
  label: "Set Up Service",
  active: true
}];
export default {
  name: "GARegistration",
  components: {
    AddServiceForm,
    DeleteDialog,
    SuccessDialog
  },
  data: () => ({
    pencilIcon,
    trashIcon,
    stepData: stepData,
    services: [],
    service: {
      name: "",
      currency: "",
      totalPrice: "",
      additionalPrice: "",
      duration: "",
      durationType: "Days",
      description: "",
      testResultSample: null,
      file: {
        name: ""
      }
    },
    submitLoading: false,
    servicesLoading: false,
    modalDelete: null,
    editId: null,
    txWeight: 0,
    showSuccessDialog: false
  }),
  computed: {
    ...mapState({
      lastEventData: state => state.substrate.lastEventData,
      api: state => state.substrate.api,
      web3: state => state.web3Store.web3,
      wallet: state => state.substrate.wallet
    }),
    ...mapGetters({
      pair: "substrate/wallet"
    })
  },
  watch: {
    $route: {
      deep: true,
      immediate: true,
      handler: async function (val) {
        await this.getData(val.params.id);
      }
    }
  },
  async created() {
    await this.getData(this.$route.params.id);
    await this.getTxWeight();
  },
  methods: {
    async getData(id) {
      var _data$info, _data$info2, _data$info3, _data$info4, _data$info5, _data$info6, _data$info7, _data$info8, _data$info9, _data$info10, _data$info11;
      const data = await queryGeneticAnalystServicesByHashId(this.api, id);
      const testResultLink = await getIpfsMetaData(data === null || data === void 0 ? void 0 : (_data$info = data.info) === null || _data$info === void 0 ? void 0 : _data$info.testResultSample.split("/").pop());
      const totalPrice = await fromEther(data === null || data === void 0 ? void 0 : (_data$info2 = data.info) === null || _data$info2 === void 0 ? void 0 : _data$info2.pricesByCurrency[0].priceComponents[0].value, data === null || data === void 0 ? void 0 : (_data$info3 = data.info) === null || _data$info3 === void 0 ? void 0 : _data$info3.pricesByCurrency[0].currency);
      const additionalPrice = await fromEther(data === null || data === void 0 ? void 0 : (_data$info4 = data.info) === null || _data$info4 === void 0 ? void 0 : _data$info4.pricesByCurrency[0].additionalPrices[0].value, data === null || data === void 0 ? void 0 : (_data$info5 = data.info) === null || _data$info5 === void 0 ? void 0 : _data$info5.pricesByCurrency[0].currency);
      const service = {
        name: data === null || data === void 0 ? void 0 : (_data$info6 = data.info) === null || _data$info6 === void 0 ? void 0 : _data$info6.name,
        currency: formatUSDTE(data === null || data === void 0 ? void 0 : (_data$info7 = data.info) === null || _data$info7 === void 0 ? void 0 : _data$info7.pricesByCurrency[0].currency),
        totalPrice: totalPrice,
        additionalPrice: additionalPrice,
        duration: data === null || data === void 0 ? void 0 : (_data$info8 = data.info) === null || _data$info8 === void 0 ? void 0 : _data$info8.expectedDuration.duration,
        durationType: data === null || data === void 0 ? void 0 : (_data$info9 = data.info) === null || _data$info9 === void 0 ? void 0 : _data$info9.expectedDuration.durationType,
        description: data === null || data === void 0 ? void 0 : (_data$info10 = data.info) === null || _data$info10 === void 0 ? void 0 : _data$info10.description,
        testResultSample: data === null || data === void 0 ? void 0 : (_data$info11 = data.info) === null || _data$info11 === void 0 ? void 0 : _data$info11.testResultSample,
        file: {
          name: testResultLink.rows[0].metadata.name
        }
      };
      this.service = {
        ...service
      };
    },
    async getTxWeight() {
      const getTxWeight = await updateGeneticAnalystServiceFee(this.api, this.wallet, this.service);
      this.txWeight = "".concat(this.web3.utils.fromWei(String(getTxWeight.partialFee), "ether"));
    },
    async DescriptionLink(description, name) {
      let blob = new Blob([description], {
        type: "text/plain"
      });
      const result = await uploadFile({
        title: "".concat(name, "DescriptionFile.txt"),
        type: blob.type,
        size: blob.size,
        file: blob
      });
      const link = getFileUrl(result.IpfsHash);
      return link;
    },
    async onSubmitService(value) {
      const id = this.$route.params.id;
      const {
        currency,
        description,
        duration,
        durationType,
        name,
        testResultSample,
        totalPrice,
        additionalPrice
      } = value;
      // the value totalPrice here is an old artifact. It is actually service price.
      // Name change could break some stuffs so it must be left as a task for another day.
      // Delete this comment once it has been fixed. ~Alex
      const servicePrice = toEther(totalPrice, currency);
      const qcPrice = toEther(additionalPrice, currency);
      const price = toEther(Number(totalPrice) + Number(additionalPrice), currency);
      const descriptionLink = await this.DescriptionLink(description, name);
      const dataToSend = {
        name,
        pricesByCurrency: [{
          currency: currency === "USDTE" ? currency : formatUSDTE(currency),
          totalPrice: price,
          priceComponents: [{
            component: "Main Price",
            value: servicePrice
          }],
          additionalPrices: [{
            component: "QC Price",
            value: qcPrice
          }]
        }],
        expectedDuration: {
          duration,
          durationType
        },
        description: descriptionLink,
        testResultSample
      };
      try {
        await updateGeneticAnalystService(this.api, this.pair, id, dataToSend);
        this.showSuccessDialog = true;
      } catch (error) {
        console.error(error);
      }
    },
    async goToServicePage() {
      this.$router.push({
        name: "ga-services"
      });
    }
  }
};